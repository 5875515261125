// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleRight           as fasFaAngleRight              } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faAngleLeft            as fasFaAngleLeft               } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faBars                 as fasFaBars                    } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown            as fasFaCaretDown               } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft            as fasFaCaretLeft               } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight           as fasFaCaretRight              } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion       as fasFaCircleQuestion          } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser           as fasFaCircleUser              } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock                as fasFaClock                   } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment              as fasFaComment                 } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder               as fasFaFolder                  } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass      as fasFaMagnifyingGlass         } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faTag                  as fasFaTag                     } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faXmark                as fasFaXmark                   } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { faUser                 as fasFaUser                    } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faLanguage             as fasFaLanguage                } from "@fortawesome/pro-solid-svg-icons/faLanguage";
import { faCalendarDays         as fasFaCalendarDays            } from "@fortawesome/pro-solid-svg-icons/faCalendarDays";
import { faPaperPlane           as fasFaPaperPlane              } from "@fortawesome/pro-solid-svg-icons/faPaperPlane";
import { faTruck                as fasFaTruck                   } from "@fortawesome/pro-solid-svg-icons/faTruck";
import { faPassport             as fasFaPassport                } from "@fortawesome/pro-solid-svg-icons/faPassport";
import { faPrint                as fasFaPrint                   } from "@fortawesome/pro-solid-svg-icons/faPrint";
import { faScannerImage         as fasFaScannerImage            } from "@fortawesome/pro-solid-svg-icons/faScannerImage";
import { faDisplay              as fasFaDisplay                 } from "@fortawesome/pro-solid-svg-icons/faDisplay";
import { faStamp                as fasFaStamp                   } from "@fortawesome/pro-solid-svg-icons/faStamp";
import { faCar                  as fasFaCar                     } from "@fortawesome/pro-solid-svg-icons/faCar";
import { faServer               as fasFaServer                  } from "@fortawesome/pro-solid-svg-icons/faServer";
import { faNewspaper            as fasFaNewspaper               } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { faAddressCard          as fasFaAddressCard             } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faExpand               as fasFaExpand                  } from "@fortawesome/pro-solid-svg-icons/faExpand";
import { faPlayCircle           as fasFaPlayCircle              } from "@fortawesome/pro-solid-svg-icons/faPlayCircle";
import { faFlower               as fasFaFlower                  } from "@fortawesome/pro-solid-svg-icons/faFlower";
import { faTree                 as fasFaTree                    } from "@fortawesome/pro-solid-svg-icons/faTree";

import { faSearch               as farFaSearch                  } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faStreetView           as farFaStreetView              } from "@fortawesome/pro-regular-svg-icons/faStreetView";
import { faLocationDot          as farFaLocationDot             } from "@fortawesome/pro-regular-svg-icons/faLocationDot";
import { faUpRightFromSquare    as farFaUpRightFromSquare       } from "@fortawesome/pro-regular-svg-icons/faUpRightFromSquare";
import { faCircleDollar         as farFaCircleDollar            } from "@fortawesome/pro-regular-svg-icons/faCircleDollar";

import { faFacebookF            as fabFaFacebookF               } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter              as fabFaTwitter                 } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube              as fabFaYoutube                 } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faInstagram            as fabFaInstagram               } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTiktok               as fabFaTiktok                  } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faPinterest            as fabFaPinterest               } from "@fortawesome/free-brands-svg-icons/faPinterest";
import { faLinkedinIn           as fabFaLinkedinIn              } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faFlickr               as fabFaFlickr                  } from "@fortawesome/free-brands-svg-icons/faFlickr";
import { faBluesky              as fabFaBluesky                 } from "@fortawesome/free-brands-svg-icons/faBluesky";

/**
 * Add solid icons
 */
library.add(
    fasFaAngleRight,
    fasFaAngleLeft,
    fasFaBars,
    fasFaCaretDown,
    fasFaCaretLeft,
    fasFaCaretRight,
    fasFaCircleQuestion,
    fasFaCircleUser,
    fasFaClock,
    fasFaComment,
    fasFaFolder,
    fasFaMagnifyingGlass,
    fasFaTag,
    fasFaXmark,
    fasFaUser,
    fasFaLanguage,
    fasFaCalendarDays,
    fasFaPaperPlane,
    fasFaTruck,
    fasFaPassport,
    fasFaPrint,
    fasFaScannerImage,
    fasFaDisplay,
    fasFaStamp,
    fasFaCar,
    fasFaServer,
    fasFaNewspaper,
    fasFaAddressCard,
    fasFaExpand,
    fasFaPlayCircle,
    fasFaFlower,
    fasFaTree
);

/**
 * Add social icons
 */
library.add(
    fabFaFacebookF,
    fabFaTwitter,
    fabFaYoutube,
    fabFaInstagram,
    fabFaTiktok,
    fabFaPinterest,
    fabFaLinkedinIn,
    fabFaFlickr,
    fabFaBluesky
);


/**
 * Add regular icons
 */
library.add(
    farFaSearch,
    farFaStreetView,
    farFaLocationDot,
    farFaUpRightFromSquare,
    farFaCircleDollar
);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
